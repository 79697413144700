<template>
  <v-sheet :color="'#fafbfc'" class="fill-height">
    <v-container fluid class="fill-height pa-0">
      <v-row class="mb-6" justify="center">
        <div class="pa-0 d-flex flex-column justify-space-between mt-md-16">
          <div
            class="
              position-relative
              company-app-list
              d-flex
              flex-column
              mx-auto
              flex-wrap
            "
          >
            <h3 class="ml-4 mt-6 font-weight-bold">
              <span>
                <span class="primary--text">Hi</span>
                <span class="grey--text text--darken-2">
                  {{ user.name || capitalize }}</span
                >
              </span>
            </h3>
            <div
              :class="`caption ${
                !$vuetify.breakpoint.mobile || 'text-center'
              } ml-4 mb-4 font-weight-regular`"
            >
              Some apps may are disabled. contact your adminstrator for further
              details
            </div>
            <div class="d-flex flex-1-1-auto flex-wrap" id="company-apps"></div>
          </div>
        </div>
      </v-row>
      <v-row></v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import CoreMixin from '@/mixins/core.mixin';

export default {
  name: 'CentralPage',
  mixins: [CoreMixin],
  data() {
    return {};
  },

  methods: {}
};
</script>

<style lang="scss">
.company-app-list {
  width: 960px;
  padding: 0 12px;
  @media only screen and (min-width: 1240px) {
    width: 936px;
  }
  @media only screen and (min-width: 920px) and (max-width: 1239px) {
    width: 840px;
    padding: 0 12px;
  }
  @media only screen and (min-width: 664px) and (max-width: 920px) {
    width: 640px;
    padding: 0 12px;
  }
  @media only screen and (max-width: 663px) {
    width: 100%;
    padding: 0 8px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.app-resources {
  display: flex;
  height: 28px;
}
</style>
